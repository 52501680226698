/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import router from '../../router/index';
import i18n from '../../config/_i18n';
import { getSuccessServiceMsg, getErrorServiceMsg } from '../../config/serviceMessages';
import isDev from '../../config/isDev';


const qs = require('qs');
var moment = require('moment');

/* const infosetApiKeyNotLoggedInEN = process.env.VUE_APP_INFOSET_CHAT_BOOT_API_KEY_NOT_LOGGED_IN_EN;
const infosetApiKeyNotLoggedInTR = process.env.VUE_APP_INFOSET_CHAT_BOOT_API_KEY_NOT_LOGGED_IN_TR; */
const infosetApiKeyLoggedInEN = process.env.VUE_APP_INFOSET_CHAT_BOOT_API_KEY_LOGGED_IN_EN;
const infosetApiKeyLoggedInTR = process.env.VUE_APP_INFOSET_CHAT_BOOT_API_KEY_LOGGED_IN_TR;
const cdnBaseUri = process.env.VUE_APP_OFINANS_CDN;

const customer = {
  state: () => ({
    isLogin: (localStorage.getItem('isLogin') && localStorage.getItem('userToken')) || '',
    login: {
      phone: '',
      password: ''
    },
    otp: {
      command: Number,
      message: '',
      code: ''
    },
    forgotPassword: {
      phone: '',
      email: ''
    },
    resetPassword: {
      email: '',
      password: '',
      password_confirmation: '',
      token: ''
    },
    user: {},
    userNotifications: [],
    userlevels: {},
    unreadNotifications: 0,
    showOtpModal: false,
    isLoading: true,
    countries: [],
    usStates: [],
    cities: [],
    towns: [],
    taxbranches: [],
    options: [],
    phoneCode: {
      title: 'US',
      desc: 'us',
      img: `${cdnBaseUri}/images/flags/US.png`,
      code: '+1',
      phoneMask: ["(###)###-####"]
    },
    register: {
      personel: {
        name: null,
        surname: null,
        email: null,
        password: null,
        passwordAgain: null,
        phone: null,
        phoneCode: {
          title: 'US',
          desc: 'us',
          img: `${cdnBaseUri}/images/flags/US.png`,
          code: '+1',
          phoneMask: ["(###)###-####"]
        },
        acceptContract: null,
        identity_number: null,
        birthday: null,
        country: null
      },
      corporate: {
        name: null,
        surname: null,
        email: null,
        password: null,
        passwordAgain: null,
        phone: null,
        phoneCode: {
          title: 'US',
          desc: 'us',
          img: `${cdnBaseUri}/images/flags/US.png`,
          code: '+1',
          phoneMask: ["(###)###-####"]
        },
        tax_number: null,
        tax_branch_id: null,
        company: null,
        country: null,
        state: null,
        city: null,
        taxOffice: null,
        acceptContract: null,
        identity_number: null,
        birthday: null
      }
    },
    showRegisterModal: false,
    showPolicyModal: false,
    showRegisterModalMessage: false,
    verifyEmail: null,
    next: '',
    currentInfosetApiKey: infosetApiKeyLoggedInEN,
    chatInfo: {},
    googleAuthQr: null,
  }),
  mutations: {
    SET_LOGIN(state, payload) {
      localStorage.setItem('userToken', payload.access_token);
      localStorage.removeItem('isLogin');
      state.otp.message = payload.message;
      if(payload.status === 'command') {
        state.showOtpModal = true;
        state.otp.command = payload.command;
      } else if(payload.status === 'ok') {
        localStorage.setItem('userToken', payload.access_token);
        router.push({path: '/wallet'});
        state.login = {phone: '', password: ''};
        state.otp = {command: null, code: ''};
      }
    },
    SET_TOKEN(state, payload) {
      localStorage.setItem('userToken', payload.access_token);
      localStorage.setItem('refreshNextTime', Date.now() + 3600000);
      state.otp = {
        ...state.otp,
        message: payload.message,
        command: payload.command || 20,
        code: ''
      };
    },
    SET_OTP_LOGIN(state, payload) {
      localStorage.setItem('userToken', payload.access_token);
      localStorage.setItem('isLogin', true);
      state.isLogin = true;
      state.login = {phone: '', password: ''};
      state.otp = {command: null, code: ''};
      state.showOtpModal = false;
      router.push({ path: '/' });
      // window.location = '/';
    },
    SET_USER_NOTIFICATIONS(state, payload) {
      state.next = payload.payload.links.next;

      if(!payload.isNextPage) {
        state.userNotifications = [];
      }
      for(let key in payload.payload.data) {
        let object = payload.payload.data[key];
        var actionTime = moment(object.updated_at, 'YYYY-MM-DD HH:mm:ssZ');
        var timeAgo = actionTime.fromNow();
        state.userNotifications.push({all_data: object, time_ago: timeAgo});
      }
    },
    BindNotificationsCount(state) {
      let result = state.userNotifications.filter(
        (el) => typeof el.all_data.read_at === 'undefined' || el.all_data.read_at === null
      );
      state.unreadNotifications = result.length;
    },
    SET_FORGOT_PASSWORD(state, payload) {
      Vue.$toast.success(payload.message);
    },
    SET_RESET_PASSWORD(state, payload) {
      if(payload.status === 'ok') {
        router.push({path: '/login'});
      }
    },
    SET_LOGOUT(state) {
      localStorage.removeItem('userToken');
      localStorage.removeItem('isLogin');
      localStorage.removeItem('maintenanceModalStatus');
      localStorage.removeItem('_dealInfoTextApproved');
      state.isLogin = false;
      window.location.href = '/login';
      // router.push({ path: '/login' });
    },
    SET_USER(state, payload) {
      state.user = payload.data;
      if(payload.data.locale !== i18n.locale) {
        Services.put(API.updateLanguage, qs.stringify({ language: i18n.locale })).then(() => {});
      }
    },
    SET_COUNTRIES(state, payload) {
      state.countries = [];
      for (let idx in payload.data) {
        let object = payload.data[idx];
        state.countries.push({
          title: object.name + ' ' + object.calling_code,
          desc: object.code,
          img: object.flag_png_url,
          code: object.calling_code,
          phoneMask: object.calling_mask_array[0] ? object.calling_mask_array : ['(###) ### ## ##'],
          name: object.name,
          id: object.id,
        });
      }
    },
    SET_US_STATES(state, payload) {
      state.usCities = [];

      state.usStates = payload.data.map(state => {
        return {title: state.name, id: state.id, state_id: state.state_id};
      });
    },
    SET_CITIES(state, payload) {
      state.cities = [];
      for(let idx in payload.data) {
        let object = payload.data[idx];
        state.cities.push({title: object.name, id: object.id});
      }
    },
    SET_TOWNS(state, payload) {
      state.towns = [];
      for(let idx in payload.data) {
        let object = payload.data[idx];
        state.towns.push({title: object.name, id: object.id});
      }
    },
    SET_TAXBRANCHES(state, payload) {
      state.taxbranches = [];
      for (let idx in payload.data) {
        let object = payload.data[idx];
        state.taxbranches.push({title: object.name, id: object.id});
      }
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    closeModal(state, playload) {
      state.showOtpModal = playload;
      state.showRegisterModal = null;
      state.showRegisterModalMessage = null;
      state.showPolicyModal = null;
    },
    phoneUpdate() {},
    SET_LEVELS(state, payload) {
      state.userlevels = payload.data;
    },
    SET_VERIFY_EMAIL(state, payload) {
      state.verifyEmail = payload;
    },
    updatePolicyModal(state, playload) {
      state.showPolicyModal = playload;
    },
    updateApiKey(state, payload) {
      state.currentInfosetApiKey = payload;
    },
    SET_CHAT_BOOT_API_KEY(state) {
      let lang = sessionStorage.getItem('lang');
      state.currentInfosetApiKey = lang === 'tr' ? infosetApiKeyLoggedInTR : infosetApiKeyLoggedInEN;
      /* if(!!state?.isLogin) {
        state.currentInfosetApiKey = lang === 'tr' ? infosetApiKeyLoggedInTR : infosetApiKeyLoggedInEN;
      } else {
        state.currentInfosetApiKey = lang === 'tr' ? infosetApiKeyNotLoggedInTR : infosetApiKeyNotLoggedInEN;
      } */
    },
    SET_CHAT_INFO(state, payload) {
      state.chatInfo = payload;
    },
    SET_PHONE_CODE(state, payload) {
      if(payload && payload.constructor === Object && Object.keys(payload).length > 0) {
        state.phoneCode = payload;
        state.register.personel.phoneCode = payload;
        state.register.corporate.phoneCode = payload;
      }
    },
    SET_GOOGLE_AUTHENTICATOR(state, payload) {
      state.googleAuthQr = payload?.qr_image || null;
    },
    onChangePersonalCountry(state, country) {
      if(country) {
        state.register.personel.phoneCode = country;
        state.register.personel.country = country;
      }
    },
    onChangeCorporateCountry(state, country) {
      if(country) {
        state.register.corporate.phoneCode = country;
        state.register.corporate.country = country;
      }
    }
  },
  actions: {
    POST_LOGIN({state, commit}, {loginType, recaptchaToken}) {
      let loginUrl = API.login;
      loginType === 'corporate' ? loginUrl += '/corporate' : loginUrl;

      Services.post(loginUrl, {
        phone: state.phoneCode.code + state.login.phone,
        password: state.login.password,
        recaptchaToken
      })
        .then((res) => {
          commit('SET_LOGIN', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async POST_SEND_CODE({commit}) {
      try {
        const res = await Services.post(API.sendCode);
        commit('SET_TOKEN', res.data);
        commit('IS_LOADING', false);
        Vue.$toast.success(res.data.message);
        return res;
      } catch(error) {
        if(error?.response?.data?.message) {
          Vue.$toast.error(error.response.data.message);
        }
        throw error;
      }
    },
    POST_LOGIN_OTP({state, commit}) {
      let code = parseInt(state.otp.code);
      Services.post(
        API.loginOtp,
        qs.stringify({
          command: state.otp.command,
          code: code
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
        .then((res) => {
          commit('SET_OTP_LOGIN', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_FORGOT_PASSWORD({state, commit}) {
      Services.post(
        API.passwordRecovery,
        qs.stringify({
          phone: state.phoneCode.code + state.forgotPassword.phone,
          email: state.forgotPassword.email
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
        .then((res) => {
          commit('SET_FORGOT_PASSWORD', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_RESET_PASSWORD({state, commit}, token) {
      state.resetPassword.token = token;
      Services.post(API.passwordReset, qs.stringify(state.resetPassword), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then((res) => {
          Vue.$toast.success(res.data.message);
          commit('SET_RESET_PASSWORD', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_USER_NOTIFICATIONS({state, commit}, params) {
      let nextPage = `${API.userNotifications}?page=1`;
      if(params.isNextPage) {
        nextPage = `${state.next}`;
      }
      Services.get(nextPage, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then((res) => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_USER_NOTIFICATIONS', data);
          commit('BindNotificationsCount');
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_READ_NOTIFICATIONS({dispatch, commit}) {
      Services.post(API.readNotifications, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(() => {
          dispatch('GET_USER_NOTIFICATIONS', {isNextPage: false});
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_USER({commit, state}) {
      Services.get(API.user, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then((res) => {
          commit('SET_USER', res.data);
          if(router.history.current.name != 'AccountSettings' && res.data.data.level < 30) {
            Vue.$toast.error(i18n.t("userLevelWarning"));
            router.push({name: 'AccountSettings'});
          }

          commit('IS_LOADING', false);
          commit('SET_CHAT_BOOT_API_KEY');
          const {id: userId, name, surname, email, phone, created_at, phone_verified, locale, type, type_str, gender, gender_str, user_hash} = res.data.data;
          const chatVisitor = {
            id: userId,
            firstName: name,
            lastName: surname,
            email,
            phone,
            photoUrl: '',
            userHash: user_hash,
            createdAt: created_at,
            company: '',
            customFields: {
              phone_verified,
              locale: locale === 'tr' ? 'tr' : 'en',
              type,
              type_str,
              gender,
              gender_str,
            },
          };
          if(!isDev) {
            // eslint-disable-next-line no-undef
            InfosetChat ('boot', {
              widget: {
                apiKey: state.currentInfosetApiKey
              },
              visitor: chatVisitor
            });
          }
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async GET_LOGOUT({commit, dispatch, state}) {
      try {
        const res = await Services.get(API.logout, {headers: {'content-type': 'application/x-www-form-urlencoded'}});
        commit('SET_LOGOUT', res.data);
        commit('IS_LOADING', false);
        commit('SET_CHAT_BOOT_API_KEY');
        await dispatch('GET_CHAT_INFO');
        if(!isDev) {
          // eslint-disable-next-line no-undef
          InfosetChat('boot', {
            widget: {
              apiKey: state.currentInfosetApiKey
            },
            visitor: {
              id: state.chatInfo.id,
              userHash: state.chatInfo.hashId
            }
          });
        }
      } catch(err) {
        if(err?.response?.data?.message) {
          Vue.$toast.error(err.response.data.message);
        }
        throw err;
      }
    },
    GET_COUNTRIES({commit}) {
      return new Promise((resolve, reject) => {
        Services.get(API.countries)
          .then((res) => {
            commit('SET_COUNTRIES', res.data);
            commit('IS_LOADING', false);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
            if(err?.response?.data?.message) {
              Vue.$toast.error(err.response.data.message);
            }
          });
      });
    },
    async GET_US_STATES({commit}) {
      const {data: states} = await Services.get(API.usStates);
      commit('SET_US_STATES', states);
    },
    CHANGE_COUNTRIES({commit, dispatch, state}, params) {
      commit('IS_LOADING', false);

      state.cities = [];
      state.register.corporate.state = null;
      state.register.corporate.city = null;
      state.register.corporate.taxOffice = null;

      params.id === 236 ? dispatch('GET_US_STATES') : dispatch('GET_CITIES', params.desc);
    },
    async GET_CITIES_PER_STATE({commit, state}, stateId) {
      state.cities = [];
      state.register.corporate.city = null;
      state.register.corporate.taxOffice = null;

      const {data: usCities} = await Services.get(API.usCities(stateId));
      commit('SET_CITIES', usCities);
    },
    GET_CITIES({commit}, value) {
      Services.get(`${API.cities}${value}`)
        .then((res) => {
          commit('SET_CITIES', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_TOWNS({commit}, value) {
      Services.get(`${API.towns}${value}`)
        .then((res) => {
          commit('SET_TOWNS', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CHANGE_CITIES({commit, dispatch, state}, params) {
      commit('IS_LOADING', false);
      state.taxbranches = [];
      state.register.taxOffice = null;
      dispatch('GET_TAXBRANCHES', params.id);
    },
    GET_TAXBRANCHES({commit}, value) {
      Services.get(`${API.taxbranches}${value}`)
        .then((res) => {
          commit('SET_TAXBRANCHES', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_PERSONEL_REGISTER({state, commit}, recaptchaToken) {
      Services.post(
        API.register,
        qs.stringify({
          name: state.register.personel.name,
          surname: state.register.personel.surname,
          email: state.register.personel.email,
          password: state.register.personel.password,
          phone: state.register.personel.phoneCode.code + state.register.personel.phone,
          recaptchaToken,
          birthday: state.register.personel.birthday,
          identity_number: state.register.personel.identity_number,
          country: state.register.personel.country.desc,
        })
      )
        .then((res) => {
          if(res.data.status == 'ok') {
            state.showRegisterModal = true;
            state.showRegisterModalMessage = res.data.message;
            commit('IS_LOADING', false);
          }
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_CORPORATE_REGISTER({state, commit}, recaptchaToken) {
      Services.post(
        `${API.register}/corporate`,
        qs.stringify({
          name: state.register.corporate.name,
          surname: state.register.corporate.surname,
          email: state.register.corporate.email,
          password: state.register.corporate.password,
          phone: state.register.corporate.phoneCode.code + state.register.corporate.phone,
          tax_number: state.register.corporate.tax_number,
          tax_branch_id: state.register.corporate.taxOffice,
          company: state.register.corporate.company,
          city: state.register.corporate.city.id,
          country: state.register.corporate.country.desc,
          us_state_id: state.register.corporate.state?.state_id,
          recaptchaToken,
          birthday: state.register.corporate.birthday,
          identity_number: state.register.corporate.identity_number,
        })
      )
        .then((res) => {
          if(res.data.status == 'ok') {
            state.showRegisterModal = true;
            state.showRegisterModalMessage = res.data.message;
            commit('IS_LOADING', false);
          }
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_LEVELS({commit}) {
      Services.get(API.userlevels)
        .then((res) => {
          commit('SET_LEVELS', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },

    VERIFY_EMAIL({commit}, params) {
      Services.post(
        API.emailverify,
        qs.stringify({
          verify_code: params.verify_code,
          id: params.id
        }),
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded'
          }
        }
      )
        .then((res) => {
          commit('SET_VERIFY_EMAIL', res.data.verified);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if(err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async GET_CHAT_INFO({commit}) {
      try {
        const res = await Services.get(API.infosetApi);
        commit('SET_CHAT_INFO', res.data);
      } catch(err) {
        if(err?.response?.data?.message) {
          Vue.$toast.error(err.response.data.message);
        }
        throw err;
      }
    },
    /**
     * by ip address
     */
    async fetchCountryCodeByIp({state, commit}) {
      try {
        let defaultCounrtyCode = "TR";
        const {countryCode} = await (await fetch('http://ip-api.com/json')).json();
        const _country = `${(countryCode ?? defaultCounrtyCode)}`.toLowerCase();
        const findCountry = state.countries?.find(country => `${country.desc}`.toLowerCase() === _country);
        commit('SET_PHONE_CODE', findCountry);
        return findCountry;
      } catch(error) {
        return error;
      }
    },
    async FETCH_GOOGLE_AUTHENTICATOR({commit}) {
      commit('IS_LOADING', true);
      try {
        const res = await Services.get(API.google_authenticator);
        commit('SET_GOOGLE_AUTHENTICATOR', res.data);
        commit('IS_LOADING', false);
        return res;
      } catch(error) {
        commit('IS_LOADING', false);
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      }
    },
    async ENABLE_GOOGLE_AUTHENTICATOR(_, verify_code) {
      try {
        const res = await Services.post(API.google_authenticator, {verify_code});
        Vue.$toast.success(getSuccessServiceMsg(res));
        return res;
      } catch(error) {
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      }
    },
    async DISABLE_GOOGLE_AUTHENTICATOR({dispatch}, password) {
      try {
        const res = await Services.post(`${API.google_authenticator}/delete`, {password});
        dispatch('GET_LOGOUT');
        return res;
      } catch(error) {
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      }
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.isLogin,
    userLevel: (state) => state.user.level,
    isAccountUpgraded: (state) => state.user?.level ? `${state.user.level}` === '30' : false,
    isIndividual: (state) => state.user?.type ? `${state.user.type}` === '10' : false,
    isCorporate: (state) => state.user?.type ? `${state.user.type}` === '20' : false,
    getFullName: (state) => {
      if(state.user?.type && `${state.user.type}` === '10') return state.user?.fullname || '';
      return state.user?.company ? state.user.company : state.user.fullname;
    },
    hasUserKktcCountry: (state) => state.user?.country ? `${state.user.country}`.toLowerCase() === 'kktc' : false,
    getUserType: (state) => state.user?.type || 10
  },
  namespaced: true
};

export default customer;
