<template>
  <div>
    <div class="form-group">
      <label class="mr-sm-2">{{ $t('deposit-title') }}</label>
      <div class="input-group">
        <multiselect v-model="selectedCurrency" @input="getCoinSelection" deselect-label="" select-label="" selected-label="" track-by="name" label="name" :custom-label="currencySelectCustomLabel" :show-labels="false" :placeholder="$t('commons.choose')" :options="depositCurrency" :allow-empty="false" open-direction="bottom">
          <!-- <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template> -->
          <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
          <template slot="noResult">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <!-- END::COUNTRIES -->
    <div class="form-group" v-if="!selectedCurrency.crypto">
      <label class="mr-sm-2">{{ $t('commons.country') }}</label>
      <div class="input-group">
        <multiselect v-model="selectedCountry" @input="onChangeCountry" deselect-label="" select-label="" selected-label="" track-by="name" label="name" :placeholder="$t('commons.choose')" :options="countries" :allow-empty="false" open-direction="bottom">
          <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name}` }}</template>
          <template slot="option" slot-scope="{ option }">{{ `${option.name}` }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <!-- END::COUNTRIES -->
    <!-- BEGIN::USDT providers -->
    <div class="form-group" v-if="selectedCurrency.crypto && selectedCurrency.code === 'USDT'">
      <label class="mr-sm-2">{{ $t('network') }}</label>
      <div class="input-group">
        <multiselect v-model="selectedNetwork" deselect-label="" select-label="" selected-label="" track-by="currency" :label="$t('network')" :options="networks" :allow-empty="false" open-direction="bottom">
          <template slot="singleLabel" slot-scope="{ option }">{{ option.currency }} </template>
          <template slot="option" slot-scope="{ option }">{{ option.currency }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
          <template slot="noResult">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <!-- END::USDT providers -->
    <template v-if="!selectedCurrency.crypto">
      <ul class="nav nav-tabs d-none">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#transfer">{{ $t('deposit-section1') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#creditcard">{{ $t('deposit-section2') }}</a>
        </li>
      </ul>
      <div class="tab-content tab-content-default">
        <div class="tab-pane fade active show" id="transfer" role="tabpanel">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(askForDepositApproval)">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-enterAmount') }}</label>
                <div class="row">
                  <validation-provider rules="required" name="Miktar" v-slot="{ classes, errors }" class="col-6">
                    <currency-input id="amount" class="form-control" :class="classes" v-model="amount" :placeholder="getAmountPlaceHolder" />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <validation-provider rules="required" :name="$t('bankSettings-bank')" v-slot="{ classes, errors }" class="col-6">
                    <multiselect v-model="selectedBank" deselect-label="" select-label="" selected-label="" track-by="id" label="bank" :placeholder="$t('deposit-dropdownBank')" :options="bankList" :allow-empty="false" :class="classes" open-direction="bottom">
                      <template slot="singleLabel" slot-scope="{ option }">{{ `${option.bank}` }}</template>
                      <template slot="option" slot-scope="{ option }">{{ `${option.bank}` }}</template>
                      <template slot="noOptions">{{ $t('noOptions') }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div v-if="selectedBank" class="d-flex w-100 mt-1">
                  <div
                    class="alert alert-warning"
                    role="alert"
                    v-html="
                      $t('deposit-limitWarning', {
                        amount: selectedBank ? selectedBank.min_pay_formatted : ''
                      })
                    "
                  ></div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <p class="mb-0">{{ $t('deposit-availableLimit') }}</p>
                  <h6 class="mb-0">{{ userBalance }}</h6>
                </div>

                <div class="d-flex justify-content-center" v-if="isLoading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="form-group" v-if="!isLoading && isDeposit">
                  <div class="d-flex justify-content-center mt-1">
                    <img id="bankLogo" :src="selectedBank.logo" />
                  </div>
                  <div class="d-flex justify-content-center mt-1 font-weight-bold">
                    {{ transfer.bank }}
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-1">
                    <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-bankRecipient') }}</span>
                    <span class="mb-0">{{ transfer.name }}</span>
                    <input name="transferName" type="hidden" ref="transferName" v-model="transfer.name" />
                    <a class="ml-1" @click="copyInformation($refs.transferName)">
                      <i class="ficon icon-layers"></i>
                    </a>
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-1">
                    <div v-html="transfer.address"></div>
                    <input name="transferAddress" type="hidden" ref="transferAddress" v-model="transfer.address" />
                    <a class="ml-1" @click="copyInformation($refs.transferAddress)">
                      <i class="ficon icon-layers"></i>
                    </a>
                  </div>
                  <div class="d-flex justify-content-center align-items-center mt-1">
                    <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-transferDesc') }}</span>
                    <span class="mb-0">{{ transfer.code }}</span>
                    <input name="transferCode" type="hidden" ref="transferCode" v-model="transfer.code" />
                    <a class="ml-1" @click="copyInformation($refs.transferCode)">
                      <i class="ficon icon-layers"></i>
                    </a>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <button type="submit" class="btn-gradient-primary btn-block">
                    {{ $t('deposit-btn-confirm') }}
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="tab-pane fade" id="creditcard" role="tabpanel">
          <div class="form-group">
            <label class="mr-sm-2">{{ $t('deposit-cc-name') }}</label>
            <div class="input-group">
              <input type="text" name="nameSurname" class="form-control" :placeholder="$t('deposit-cc-name')" />
            </div>
          </div>
          <div class="form-group">
            <label class="mr-sm-2">{{ $t('deposit-cc-number') }}</label>
            <div class="input-group">
              <input type="text" name="cardNo" class="form-control" :placeholder="$t('deposit-cc-number')" />
            </div>
          </div>
          <label class="mr-sm-2">{{ $t('deposit-cc-dateTime') }}</label>
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-cc-month') }}</label>
                <input type="text" name="mounth" class="form-control" :placeholder="$t('deposit-cc-month-placeholder')" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-cc-year') }}</label>
                <input type="text" name="year" class="form-control" :placeholder="$t('deposit-cc-year-placeholder')" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-cc-cvv') }}</label>
                <input type="text" name="cvv" class="form-control" :placeholder="$t('deposit-cc-cvv')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="alert alert-warning" role="alert" v-html="$t('min_deposit_crypto_amount_warning')"></div>
      <div class="d-flex justify-content-center mt-1">
        <span class="mb-0 font-weight-bold">{{ $t('deposit-walletAddress') }}</span>
      </div>
      <template v-if="wallet.address">
        <div class="d-flex justify-content-center mt-1 position-relative">
          <qrcode-vue :value="wallet.address" :size="qrSize" level="H" class="wallet-address-qr" />
        </div>
        <div class="d-flex justify-content-center mt-1">
          <span class="walletAddress">{{ wallet.address }}</span>
          <input name="currencyWallet" type="hidden" ref="currencyWallet" v-model="wallet.address" />
        </div>
        <div class="d-flex justify-content-center mt-1">
          <button type="button" @click="copyInformation($refs.currencyWallet)" class="btn btn-blue ml-1 mb-0">
            <i class="ficon icon-layers"></i>
            {{ $t('wallet-detail-btn-copy') }}
          </button>
        </div>
      </template>
      <template v-if="selectedCurrency.code === 'XRP' || selectedCurrency.code === 'XLM'">
        <div class="d-flex justify-content-center mt-3">
          <span class="font-weight-bold" v-if="selectedCurrency.code === 'XRP'">{{ $t('deposit-walletTag') }}</span>
          <span class="font-weight-bold" v-if="selectedCurrency.code === 'XLM'">{{ $t('deposit-memo') }}</span>
        </div>
        <div class="d-flex justify-content-center mt-1">
          <span>{{ targetValue }}</span>
          <input name="currencyWallet" type="hidden" ref="targetValue" v-model="targetValue" />
        </div>
        <div class="d-flex justify-content-center mt-1">
          <button type="button" @click="copyInformation($refs.targetValue)" class="btn btn-blue ml-1">
            <i class="ficon icon-layers"></i>
            {{ $t('wallet-detail-btn-copy') }}
          </button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import Swal from 'sweetalert2';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'DepositForm',
  components: {
    Multiselect,
    QrcodeVue
  },
  data: () => ({
    qrSize: 125
  }),
  computed: {
    ...mapState({
      currency: (state) => state.global.currency,
      isLoading: (state) => state.global.isLoading,
      user: (state) => state.customer.user,
      isCrypto: (state) => state.deposit.isCrypto,
      wallet: (state) => state.deposit.wallet,
      bankList: (state) => state.deposit.bankList,
      userBalance: (state) => state.deposit.userBalance,
      isDeposit: (state) => state.deposit.isDeposit,
      isTransfer: (state) => state.deposit.isTransfer,
      transfer: (state) => state.deposit.transfer,
      countries: (state) => state.customer.countries,
      networks: (state) => state.deposit.networks
    }),
    selectedBank: {
      get() {
        return this.$store.state.deposit.selectedBank;
      },
      set(value) {
        this.$store.commit('deposit/updateSelectedBank', value);
        if (value?.id) {
          this.FETCH_TRANSFER({
            provider_id: value.id,
            currency: this.selectedCurrency.code
          });
        }
      }
    },
    selectedCurrency: {
      get() {
        return this.$store.state.deposit.selectedCurrency;
      },
      set(value) {
        this.CANCEL_DEPOSIT();
        this.$store.commit('deposit/updateSelectedCurrency', value);
      }
    },
    amount: {
      get() {
        return this.$store.state.deposit.amount;
      },
      set(value) {
        this.$store.commit('deposit/updateAmount', value);
      }
    },
    targetValue: function () {
      return this.user.id + 1000000000;
    },
    depositCurrency: function () {
      return this.currency.filter(function (currency) {
        return currency.deposit;
      });
    },
    selectedCountry: {
      get() {
        return this.$store.state.deposit.selectedCountry;
      },
      set(value) {
        this.CANCEL_DEPOSIT();
        this.$store.commit('deposit/updateSelectedCountry', value);
      }
    },
    getAmountPlaceHolder() {
      if (this.selectedBank) {
        return `Min: ${this.selectedBank?.min_pay || '10'}`;
      }
      return this.$t('deposit-labelAmount');
    },
    selectedNetwork: {
      get() {
        return this.$store.state.deposit.selectedNetwork;
      },
      set(value) {
        this.$store.commit('deposit/onChangeSelectedNetwork', value);
      }
    }
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions('deposit', ['GET_DEPOSIT_PROVIDERS', 'GET_CURRENCY_BALANCE', 'FETCH_TRANSFER', 'POST_CREATE_DEPOSIT', 'CANCEL_DEPOSIT', 'GET_HISTORY']),
    getCoinSelection() {
      const currency = this.selectedCurrency.code.toLowerCase();
      this.GET_HISTORY({ currency, isNextPage: false });
      this.getProviders();
      this.GET_CURRENCY_BALANCE(currency);
      this.selectedBank = null;
      this.$refs.form?.reset();
    },
    fetchTransfer() {
      this.FETCH_TRANSFER({
        provider_id: this.selectedBank.id,
        currency: this.selectedCurrency.code
      });
    },
    askForDepositApproval() {
      const minToDeposit = this.selectedBank?.min_pay || 10;
      if (parseFloat(this.amount) < minToDeposit) {
        Vue.$toast.error(
          this.$t('deposit-invalidAmount', {
            amount: this.selectedBank.min_pay_formatted ? this.selectedBank.min_pay_formatted : ''
          })
        );
        return;
      }
      Swal.fire({
        title: this.$t('modal-title-warning'),
        text: this.$t('deposit-askForUserApproval'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes'),
        cancelButtonText: this.$t('commons.cancel')
      }).then((result) => {
        if (result.value) {
          this.approveDeposit();
        }
      });
    },
    approveDeposit() {
      this.POST_CREATE_DEPOSIT()
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelDeposit() {
      this.CANCEL_DEPOSIT();
    },
    copyInformation(params) {
      params.setAttribute('type', 'text');
      params.select();
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      params.setAttribute('type', 'hidden');
      Vue.$toast.info(this.$t('toast-copyText'));
    },
    onChangeCountry() {
      this.selectedBank = null;
      this.$refs.form?.reset();
      this.getProviders();
    },
    getProviders() {
      const currency = this.selectedCurrency.code.toLowerCase();
      const country = this.selectedCountry;
      if (this.selectedCurrency.crypto) {
        this.GET_DEPOSIT_PROVIDERS({ currency, country_id: '' });
        return;
      }
      if (currency && country?.id) {
        this.GET_DEPOSIT_PROVIDERS({ currency, country_id: country?.id });
      }
    },
    currencySelectCustomLabel({ name, code }) {
      return `${name} (${code})`;
    }
  },
  async created() {
    await this.GET_CURRENCY();
    await this.$store.dispatch('customer/GET_COUNTRIES');
    if (!this.selectedCountry && this.countries.length > 0) {
      this.selectedCountry = this.countries[0];
    }
    this.getProviders();
    this.GET_CURRENCY_BALANCE(this.selectedCurrency.code.toLowerCase());
  }
};
</script>

<style lang="scss" scoped>
.walletAddress {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  height: 40px;

  &:hover {
    overflow: auto;
    white-space: inherit;
    text-overflow: unset;
  }
}

@media screen and (max-width: 768px) {
  .nav.nav-tabs {
    height: 40px;
    margin-bottom: 10px;

    .nav-item {
      height: 40px;

      a {
        line-height: 20px;
      }
    }
  }
  #bankLogo {
    width: 100%;
  }
}

.wallet-address-qr {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  &:hover {
    transform: scale(120%);
    box-shadow: 0 0 40px 32px rgba(0, 0, 0, 0.09);
  }
}
</style>
