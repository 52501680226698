<template>
    <div>
        <div class="row align-items-center">
            <div class="col-12 col-md-4">
                <multiselect v-model="selectedCurrency" @input="getCoinSelection" deselect-label="" select-label=""
                    selected-label="" id="currency" track-by="name" :label="$t('commons.currency')"
                    :custom-label="currencySelectCustomLabel" :show-labels="false" :placeholder="$t('commons.currency')"
                    :options="activeCurrency" :allow-empty="false" open-direction="bottom">
                    <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                    <template slot="noOptions">{{ $t('noOptions') }}</template>
                    <template slot="noResult">{{ $t('noOptions') }}</template>
                </multiselect>
            </div>
            <div class="col-12 col-md-8 w-100">
                <div class="d-flex overflow-auto">
                    <button v-for="(tabType, tabTypeIdx) in tabTypes" :key="`tab-${tabType.tab}`" type="button"
                        style="height: 54.5px; min-width: 110px; font-size: 16px;" class="btn bg-lighten-1"
                        :class="[selectedTab === tabType.tab ? 'bg-primary text-white' : 'text-secondary', tabTypeIdx !== 0 && 'ml-2']"
                        @click="selectedTab = tabType.tab">
                        {{ tabType.label }}
                    </button>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-hover table-xl">
                <thead>
                    <tr>
                        <th class="border-top-0">{{ $t('history-transaction') }}</th>
                        <th class="border-top-0">{{ $t('history-dateHour') }}</th>
                        <th class="border-top-0">{{ $t('commons.price') }}</th>
                        <th class="border-top-0">{{ $t('commons.amount') }}</th>
                        <th class="border-top-0">{{ $t('commons.total') }}</th>
                        <th class="border-top-0">{{ $t('commons.commission') }}</th>
                        <th class="border-top-0">{{ $t('history-status') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="isLoading">
                        <tr>
                            <td colspan="6" class="text-center loading-content">
                                <content-loader :width="1000" :height="63" :speed="2" primaryColor="#ffffff"
                                    secondaryColor="#eee" class="loading-bg" v-for="(item, index) in 3"
                                    :key="'loading-' + index">
                                    <rect x="0" y="0" rx="6" ry="6" width="1000" height="83" />
                                </content-loader>
                            </td>
                        </tr>
                    </template>
                    <template v-else-if="filterHistory.length === 0">
                        <tr>
                            <td colspan="6" class="text-center">{{ $t('history-noData') }}</td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr v-for="(item, index) in filterHistory" :key="'history-' + index"
                            :class="['progress-status', progressStatusColors[getProgressTypeByItemType(item.type_code)][item.status]]">
                            <td class="text-truncate">
                                <span class="progress-type" :class="[getProgressTypeByItemType(item.type_code)]">{{
                                    item.type }}</span>
                            </td>
                            <td class="text-truncate">{{ item.created_at | dateFormat }}</td>
                            <td class="text-truncate">{{ item.price_formatted }}</td>
                            <td class="text-truncate">{{ item.amount_formatted }}</td>
                            <td class="text-truncate">{{ item.total_formatted }}</td>
                            <td class="text-truncate">{{ item.fee_formatted }}</td>
                            <td class="text-truncate">
                                <span class="progress-text d-flex justify-content-start align-items-center">
                                    <i class="la la-dot-circle-o font-medium-1 m-r-5"></i>
                                    {{ item.status_str }}
                                </span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class="col-12 text-center mt-2 mb-2" v-if="next != null">
                <button type="button" class="btn-gradient-primary" @click="getMore">{{ $t('history-loadMore') }}</button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import dateFormat from '@/config/_date-format';
import { ContentLoader } from 'vue-content-loader';
import utils from "@/config/utils";

export default {
    name: 'TransactionHistoryList',
    components: {
        Multiselect,
        ContentLoader
    },
    data() {
        return {
            tabTypes: [
                { tab: "all", label: this.$t('commons.all') },
                { tab: "withdraw", label: this.$t('header-menu-help-withdraw') },
                { tab: "deposit", label: this.$t('top-menu-deposit') },
                { tab: "quicksale-buy", label: this.$t('instanttrade-buynow') },
                { tab: "quicksale-sell", label: this.$t('instanttrade-sellnow') },
            ],
            selectedTab: 'all',
            selectedCurrency: {
                code: 'TRY',
                name: this.$t('wallet-turkish-lira')
            }
        };
    },
    filters: {
        dateFormat
    },
    computed: {
        ...mapState({
            history: (state) => state.global.history,
            next: (state) => state.global.next,
            currency: (state) => state.global.currency,
            isLoading: (state) => state.global.isLoading,
            progressStatusColors: (state) => state.global.progressStatusColors
        }),
        activeCurrency: function () {
            return this.currency.filter(function (currency) {
                return currency.balance;
            })?.sort((a, b) => utils.sortArrByString(a.code, b.code));
        },
        filterHistory() {
            return this.history.filter((hist) => {
                if (this.selectedTab === 'withdraw') {
                    return ['withdraw', 'transfer'].includes(hist.type_code);
                }
                if (this.selectedTab !== 'all') {
                    return hist.type_code === this.selectedTab;
                }
                
                return hist;
            });
        },
    },
    methods: {
        ...mapActions('global', ['GET_HISTORY', 'GET_CURRENCY']),
        getCoinSelection() {
            this.selectedTab = 'all';
            this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
        },
        getMore() {
            if (this.next) this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: true });
        },
        currencySelectCustomLabel({ name, code }) {
            return `${name} (${code})`
        },
        getProgressTypeByItemType(itemType) {
            let types = {
                'withdraw': 'withdraw',
                'deposit': 'deposit',
                'quicksale-buy': 'quicksale',
                'quicksale-sell': 'quicksale'
            };
            return types[itemType] || 'transfer';
        }
    },
    created() {
        this.GET_CURRENCY();
        this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
    }
};
</script>

  